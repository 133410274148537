import React, { useState, useRef, useCallback } from "react";
import Area from "./Area";
import ip from "../../config_ip";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableHeader,
} from "@/components/ui/table";
import {
  Tabs,
  TabsList,
  TabsTrigger,
  TabsContent,
} from "@/components/ui/tabs.jsx";
import { Button } from "@/components/ui/button";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import Viewbox from "../UI/Viewbox";
import { Spinner } from "react-bootstrap";
import { useAllFetch } from "../CustomHooks/useAllFetch";
import { useAuth } from "@clerk/clerk-react";
import { Loading } from "../UI/Loading";
import { NoResults } from "../UI/NoResults";
import { useTranslation } from "react-i18next";

const para = window.location.pathname.split("/");
const jobId = para[2];
const contractor_id = localStorage.getItem("contractor_id");

export default function Areas() {
  const { t } = useTranslation();
  const [claimPictures, setClaimPictures] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("areas");
  const [picturesLoaded, setPicturesLoaded] = useState(false);
  const fileInputRef = useRef();

  const areasByJob = useAllFetch(["areasbyjob"], "/areasbyjob/" + jobId);
  const { getToken } = useAuth();

  const fetchPictures = useCallback(async () => {
    setLoading(true);
    const token = await getToken();
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await fetch(
        ip.url + "/getPictures/" + jobId + "/" + 0,
        options
      );
      const result = await response.json();
      setClaimPictures(result.data);
    } catch (error) {
      console.error("Error fetching claim pictures:", error);
    } finally {
      setLoading(false);
      setPicturesLoaded(true);
    }
  }, [getToken]);

  const handleTabChange = (value) => {
    setActiveTab(value);
    if (value === "documents" && !picturesLoaded) {
      fetchPictures();
    }
  };

  const handleDelete = (deletedFile) => {
    setClaimPictures(
      claimPictures.filter((file) => file.filename !== deletedFile.filename)
    );
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadPicture(file);
    }
  };

  const uploadPicture = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("picture", file);
    const token = await getToken();
    try {
      await fetch(ip.url + "/uploadPicture/" + jobId + "/" + 0, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchPictures();
    } catch (error) {
      console.error("Error uploading picture:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  let total_bl = 0;
  let total_dh = 0;

  if (areasByJob.isPending) {
    return <Loading />;
  } else {
    areasByJob.data?.forEach((areajob) => {
      total_bl += areajob.bl;
      total_dh += areajob.dh;
    });
  }

  return (
    <div className="container">
      <div className="font-bold text-2xl mb-2">
        {t("AreasAt")} {areasByJob.data?.[0]?.address.split(",")[0]}
      </div>
      <div className="mb-3">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href={"/jobs/" + contractor_id}>
                {t("Claims")}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href={window.location.href}>
                {t("Areas")}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      <Tabs value={activeTab} onValueChange={handleTabChange}>
        <TabsList>
          <TabsTrigger value="areas">{t("Areas")}</TabsTrigger>
          <TabsTrigger value="documents">{t("ClaimDocuments")}</TabsTrigger>
        </TabsList>
        <TabsContent value="areas">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t("Name")}</TableHead>
                <TableHead>{t("Status")}</TableHead>
                <TableHead>{t("TimeBreakdown")}</TableHead>
                <TableHead>{t("DryingSetup")}</TableHead>
                <TableHead>{t("RobbieId")}</TableHead>
                <TableHead>{t("Actions")}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {areasByJob.data?.map((area, index) => (
                <Area area={area} key={index} />
              ))}
            </TableBody>
            <TableFooter align="left">
              <TableRow>
                <TableCell>
                  <div className="text-sm font-normal text-muted-foreground">
                    {t("Areas")}: {areasByJob.data?.length}
                  </div>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <div className="text-sm font-normal text-muted-foreground">
                    {t("Dehumidifiers")}: {total_dh}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="text-sm font-normal text-muted-foreground">
                    {t("AirMover")}: {total_bl}
                  </div>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TabsContent>
        <TabsContent value="documents">
          {!loading ? (
            <>
              <div className="claim-pictures">
                {claimPictures.length > 0 ? (
                  claimPictures.map((file) => (
                    <Viewbox
                      key={file.filename}
                      file={file}
                      jobid={jobId}
                      onDelete={handleDelete}
                    />
                  ))
                ) : (
                  <NoResults message={t("NoPictures")} />
                )}
              </div>
              <div className="w-full text-center mt-2">
                <Button onClick={handleClick}>{t("AddClaimDocsPict")}</Button>
              </div>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </>
          ) : (
            <div className="flex-div">
              <Spinner animation="border" variant="info" />
              <div>
                <h3>{t("LoadingFile")}</h3>
              </div>
            </div>
          )}
        </TabsContent>
      </Tabs>
    </div>
  );
}